import ReactFlow, {Controls,Background } from 'react-flow-renderer';
import map from './map';
import './Flow.css';
function Flow() {
  const edgeOptions = {
    animated: true,
    style: {
      stroke: 'black',
    },
  };
  return (
    <ReactFlow defaultNodes={map.nodes} defaultEdges={map.edges} nodesDraggable={false} nodesConnectable={false} defaultEdgeOptions={edgeOptions}>
      <Background color="#808080"/>
      <Controls showInteractive={ false} />

    </ReactFlow>
  );
}
export default Flow;

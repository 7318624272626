import NodeContent from "./NodeContent"

export default {
    nodes: [
        {
            id: 'dre',
            style: {width: 'auto',},
            type: "input",
            data: { label:
                     <NodeContent title="Dreikörperproblem" links={[
                         ["https://arxiv.org/pdf/1508.02312v1.pdf","The three-body problem (Musielak et al.) (1-6)"],
                         ["https://www.cambridge.org/core/books/threebody-problem/7A0E7E318573D1BE8A00A50B4D3A4813","The three-body problem (Valtonen et al.) (1-5)"],
                         ["https://dspace.mit.edu/handle/1721.1/119107","Solving the N-body problem in astrophysics (Hernandez et al.) (19-34)"],
                        ]
                        }/>
                },
            position: { x: 600, y: 200 },
              
        },
        {
            id: 'phy',
            style: {width: 'auto',},
            data: {label: <NodeContent title="Physik" links={[
                
            ]}/>},
            position: { x: 1300, y: 400 },
        },
        {
            id: 'new',
            style: {width: 'auto',},
            data: {label: <NodeContent title="Newtonsche Gravitation" links={[
                ["https://www.tib.eu/de/suchen/id/TIBKAT:1776053036/Grundlagen-der-Orbitmechanik?cHash=87db7734a63666e589420adb51bfda58","Grundlagen der Orbitmechanik (Maiwald et al.) (146-162)"],
                ["https://books.google.de/books?id=uWVQrIZqz_MC&pg=PA143&redir_esc=y#v=onepage&q&f=false", "Physics for the IB Diploma (K.A. Tsokos) (143)"],
                ["https://www.spektrum.de/lexikon/physik/massenpunkt/9474","Massenpunkt (Spektrum)"],
                ["https://arxiv.org/pdf/1508.02312v1.pdf","The three-body problem (Musielak et al.) (6-8)"],
            ]}/>},
            position: { x: 1400, y: 500 },
        },
        {
            id: 'ham',
            style: {width: 'auto',},
            data: {label: <NodeContent title="Hamilton-Mechanik" links={[
                ["https://www.tat.physik.uni-tuebingen.de/~schaefer/teach/f/chaos_english.pdf","Practical Course in Astronomy (Universität Tübingen) (1-3)"],
                ["https://arxiv.org/pdf/1508.02312v1.pdf","The three-body problem (Musielak et al.) (9-10)"],
                ["https://www.uni-muenster.de/imperia/md/content/physik_ft/pdf/ws1112/seminar/111918/willems-kemper.pdf", "Das 3-Körper-Problem (4)"],
                ["https://www.spektrum.de/lexikon/physik/verallgemeinerte-koordinaten/15122","Verallgemeinerte Koordinaten (Spektrum)"],
                ["https://www.spektrum.de/lexikon/physik/freiheitsgrad/5308","Freiheitsgrad (Spektrum)"],
                ["spektrum.de/lexikon/physik/analytische-mechanik/502", "Analytische Mechanik (Spektrum)"],
                ["https://www.cambridge.org/core/books/threebody-problem/7A0E7E318573D1BE8A00A50B4D3A4813","The three-body problem (Valtonen et al.) (80-87)"],
            ]}/>},
            position: { x: 1700, y: 500 },
        },
        {
            id: 'erh',
            style: {width: 'auto',},
            data: {label: <NodeContent title="Erhaltungsgrößen" links={[
                ["https://www.tib.eu/de/suchen/id/TIBKAT:1776053036/Grundlagen-der-Orbitmechanik?cHash=87db7734a63666e589420adb51bfda58","Grundlagen der Orbitmechanik (Maiwald et al.) (147-149)"],
                ["https://www.uni-muenster.de/imperia/md/content/physik_ft/pdf/ws1112/seminar/111918/willems-kemper.pdf", "Das 3-Körper-Problem (7-8)"],
                    
                
            ]}/>},
            position: { x: 1100, y: 600 },
        },
        {
            id: 'sim',
            style: {width: 'auto',},
            data: {label: <NodeContent title="Simulation" links={[
                
            ]}/>},
            position: { x: 300, y: 400 },
        },
        {
            id: 'num',
            style: {width: 'auto',},
            data: {label: <NodeContent title="Numerische Verfahren" links={[
                ["https://ia902301.us.archive.org/2/items/c-36_20211010/C36.pdf","Numerical Methods in Engineering (246-293)"],
                ["https://e-maxx.ru/bookz/files/numerical_recipes.pdf","Numerical Recipes (899-928)"],
                ["https://www.tib.eu/de/suchen/id/TIBKAT:1656248980/Computational-Physics-Simulation-of-Classical-and?cHash=37dcbec04456f3e2da6ea3a594702c95", "Computational Physics (Scherer et al.) (132-149)"],
                ["https://dspace.mit.edu/handle/1721.1/119107","Solving the N-body problem in astrophysics (Hernandez et al.) (37-65)"],
                ["https://arxiv.org/pdf/1508.02312v1.pdf","The three-body problem (Musielak et al.) (19-20)"],
            ]}/>},
            position: { x: 200, y: 500 },
        },
        {
            id: 'eul',
            style: {width: 'auto',},
            data: {label: <NodeContent title="Euler-(Cromer)-Verfahren"
        links={
            [
                ["https://www.tat.physik.uni-tuebingen.de/~schaefer/teach/f/chaos_english.pdf","Practical Course in Astronomy (Universität Tübingen) (5-8)"],
                ["https://www.astro.physik.uni-potsdam.de/~htodt/ca/three-n-body.pdf", "Computational Astrophysics (Todt) (7-9)"],

                ["https://www.academia.edu/42838651/Fourth_Edition_An_Introduction_to_Numerical_Methods_A_MATLAB_Approach","An Introduction to Numerical Methods (344-350)"],

                ["https://ia902301.us.archive.org/2/items/c-36_20211010/C36.pdf","Numerical Methods in Engineering (247-252)"],
            ]
        }/>},
            position: { x: 0, y: 700 },
        },
        
        {
            id: 'lea',
            style: {width: 'auto',},
            data: {label: <NodeContent title="Leapfrog-Verlet-Verfahren"
            links={
                [
                    ["https://www.tat.physik.uni-tuebingen.de/~schaefer/teach/f/chaos_english.pdf","Practical Course in Astronomy (Universität Tübingen) (9)"],
                    ["https://dspace.mit.edu/handle/1721.1/119107","Solving the N-body problem in astrophysics (Hernandez et al.) (71-75)"],
                    ["https://young.physics.ucsc.edu/115/leapfrog.pdf", "The leapfrog method and other “symplectic” algorithms (Young)"],
                    ["https://www.astro.physik.uni-potsdam.de/~htodt/ca/three-n-body.pdf", "Computational Astrophysics (Todt) (10-15)"],
                    ["https://journals.aps.org/pr/pdf/10.1103/PhysRev.159.98","Computer Experiments on Classical Fluids (Verlet)"]
                        
                ]
            }/>},
            position: { x: 300 , y: 900 },
        },
        {
            id: 'run',
            style: {width: 'auto',},
            data: {label: <NodeContent title="Runge-Kutta-Verfahren"
            links={
                [
                    ["https://www.tat.physik.uni-tuebingen.de/~schaefer/teach/f/chaos_english.pdf","Practical Course in Astronomy (Universität Tübingen) (10-11)"],
                    ["https://www.astro.physik.uni-potsdam.de/~htodt/ca/three-n-body.pdf", "Computational Astrophysics (Todt) (17-25)"],
                ["https://www.academia.edu/42838651/Fourth_Edition_An_Introduction_to_Numerical_Methods_A_MATLAB_Approach","An Introduction to Numerical Methods (359-373)"],

                ["https://ia902301.us.archive.org/2/items/c-36_20211010/C36.pdf","Numerical Methods in Engineering (252-263)"],
                ]
            }/>},
            position: { x: 470, y: 700 },
        },
        {
            id: 'bul',
            style: {width: 'auto',},
            data: {label: <NodeContent title="Bulirsch-Stoer-Verfahren" links={[
                ["https://www.astro.physik.uni-potsdam.de/~htodt/ca/three-n-body.pdf", "Computational Astrophysics (Todt) (26-30)"],

                ["https://e-maxx.ru/bookz/files/numerical_recipes.pdf","Numerical Recipes (718)"],

                ["https://ia902301.us.archive.org/2/items/c-36_20211010/C36.pdf","Numerical Methods in Engineering (280-287)"],
            ]
            }/>},
            position: { x: -300, y: 700 },
        },
        {
            id: 'int',
            style: {width: 'auto',},
            data: {label: <NodeContent title="Polynomial-Interpolation"
            links={[
                ["https://www.astro.physik.uni-potsdam.de/~htodt/ca/three-n-body.pdf", "Computational Astrophysics (Todt) (30)"],
                ["https://www.academia.edu/42838651/Fourth_Edition_An_Introduction_to_Numerical_Methods_A_MATLAB_Approach","An Introduction to Numerical Methods (153-172)"],

                ["https://ia902301.us.archive.org/2/items/c-36_20211010/C36.pdf","Numerical Methods in Engineering (105-120)"],

            ]}/>},
            position: { x: -300, y: 850 },
        },
        {
            id: 'ric',
            style: {width: 'auto',},
            data: {label: <NodeContent title="Richardson-Extrapolation"
            links={[
                ["https://de.wikipedia.org/wiki/Richardson-Extrapolation","Richardson-Extrapolation (Wikipedia)"],
                ["https://www.astro.physik.uni-potsdam.de/~htodt/ca/three-n-body.pdf", "Computational Astrophysics (Todt) (28-29)"],
                ["https://e-maxx.ru/bookz/files/numerical_recipes.pdf","Numerical Recipes (719)"],
            ]}/>},
            position: { x: -600, y: 850 },
        },
        {
            id: 'feh',
            style: {width: 'auto',},
            data: {label: <NodeContent title="Fehleranalyse"
            links={[
                ["https://arxiv.org/pdf/1411.6671.pdf","On the Reliability of N-body Simulations (Boekholt et al.)"],
                ["https://www.academia.edu/42838651/Fourth_Edition_An_Introduction_to_Numerical_Methods_A_MATLAB_Approach","An Introduction to Numerical Methods (23-34,350-355)"],

            ]}/>},
            position: { x: 790, y: 810 },
        },
        {
            id: 'cha',
            style: {width: 'auto',},
            data: {label: <NodeContent title="Chaos"
            links={[
                ["https://www.cambridge.org/core/books/threebody-problem/7A0E7E318573D1BE8A00A50B4D3A4813","The three-body problem (Valtonen et al.) (39-43)"],
            ]}/>},
            position: { x: 700, y: 400 },
        },
        {
            id: 'pyt',
            style: {width: 'auto',},
            data: {label: <NodeContent title="Pythagoreisches Dreikörperproblem"
            links={[
                ["https://www.researchgate.net/publication/226415727_Global_chaoticity_in_the_Pythagorean_three-body_problem", "Global chaoticity in the Pythagorean three-body problem"],
                ["https://adsabs.harvard.edu/full/1967AJ.....72..876S", "Complete solution of a general problem of three bodies"]           
            ]
        }/>},
            position: { x: 900, y: 500 },
        },
        {
            id: 'ins',
            style: {width: 'auto',},
            data: {label: <NodeContent title="Instabilität"
            links={
                [
                    ["https://adsabs.harvard.edu/full/1993ApJ...415..715G","On the Exponential Instability of N-Body Systems"],

                ]
            }/>},
            position: { x: 600, y: 500 },
        },
        {
            id: 'mac',
            style: {width: 'auto',},
            data: {label: <NodeContent title="Machine Learning"
            links={
                [
                    ["https://www.researchgate.net/publication/343563677_Can_machine_learning_really_solve_the_three-body_problem","Can machine learning really solve the three-body problem?"],
                    ["https://arxiv.org/abs/1910.07291", "Solving the chaotic three-body problem using deep neural networks"],
                ]
            }/>},
            position: { x: -200, y: 500 },
        },
    ],
    edges: [
        { id: 'e1-2', source: 'dre', target: 'phy' },
        { id: 'e1-6', source: 'phy', target: 'ham' },
        { id: 'e2-3', source: 'phy', target: 'new' },
        { id: 'e2-4', source: 'dre', target: 'sim' },
        { id: 'e2-5', source: 'sim', target: 'feh' },
        { id: 'e6-7', source: 'sim', target: 'num' },
        { id: 'e7-8', source: 'num', target: 'lea' },
        { id: 'e7-9', source: 'num', target: 'run' },
        { id: 'e8-10', source: 'num', target: 'eul' },
        { id: 'e10-9', source: 'num', target: 'bul' },
        {id: 'e7-11', source: 'eul', target: 'eulc'},
        {id: "e7-12", source: "eul", target: "lea"},
        {id: "e6-13", source: "phy", target: "erh"},
        {id: "a", source: "erh", target: "feh"},
        {id: "b", source: "dre", target: "cha"},
        {id: "c", source: "sim", target: "mac"},
        {id: "d", source: "ins", target: "feh"},
        {id: "e", source: "cha", target: "ins"},
        {id: "f", source: "cha", target: "pyt"},
        {id: "g", source: "bul", target: "int"},
        {id: "h", source: "bul", target: "ric"},
    ]
}
const NodeContent = (props) => {
    let items ;
    if (props.links != null) {
        items = props.links.map((item) => 
        <li key={item[1]}
        style={{
            listStylePosition: "outside",
            padding: "0",
            }}>
            {item[0] !== "" ? (<a href={item[0]} 
            style={{
                color:  "blue",
                textDecoration: "none",
                fontSize: "0.8em",
            }}>
                {item[1]} 
            </a>):(<span style={{fontSize: "0.8em"}}>{item[1]}</span>)}
        </li>);
    }
    return (
        <div>
            {props.title}
            { props.links != null ? <ul
            style={{paddingLeft: "0", listStyle:"none"}}>
            {items}</ul> : null}
        </div>
    );
}
 
export default NodeContent;
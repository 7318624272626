import React from 'react';
import ReactDOM from 'react-dom/client';
import Flow from './Flow';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <div style={{height: '100vh'}}>
    <Flow></Flow></div>
  </React.StrictMode>
);

